import * as ReactDOM from 'react-dom'
import React from 'react'
import App from './App'
import './index.css'

// import { CookiesProvider } from 'react-cookie'

/* const container = document.getElementById('root')
const root = createRoot(container) */
ReactDOM.render(
            <App />,
    document.getElementById('root'))