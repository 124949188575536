import React from 'react'
import Logo from '../../assets/img/logo.png'


const GHeader = () => {
  return (
    <header className='h-[14rem] pt-1 sm:pt-[1rem] max-sm:px-1 overflow-hidden'>
      <div className="container mx-auto h-full text-center items-center relative" >
        <h4 className='mt-3 text-lg sm:text-xl'>Okumo Pictures</h4>
        <h1 className='mt-3 text-xl sm:text-2xl font-bold'>Ruben MARGUERITE</h1>
        <div className='mt-[1rem] sm:mt-[1rem] px-1 sm:px-2 pt-2 sm:pt-4 bg-gradient-to-b from-daisy to-transparent rounded-t-full w-[4rem] sm:w-20 h-1/2 absolute left-[calc(50%-2.25rem)] sm:left-[calc(50%-2.75rem)]'>
          <img src={Logo} alt="Logo Okumo" />
        </div>
      </div>
    </header>
  )
}

export default GHeader