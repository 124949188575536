import React from 'react'
import {BsLinkedin} from 'react-icons/bs'

const HSocials = () => {
  return (
    <div className='flex flex-col items-center gap-4 absolute left-4 sm:left-0 bottom-10 after:w-px after:h-8 after:bg-daisy'>
        <a href="https://www.linkedin.com/in/ruben-marguerite-821178b0/" target="_blank" rel="noopener noreferrer"><BsLinkedin/></a>
    </div>
  )
}

export default HSocials