import React from 'react'

// import FPRP from '../../assets/img/projets/FPRP.png'

const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h2>Certifications</h2>

        <div className="container grid grid-cols-1 gap-10 mx-auto w-[35%]">
          <article className='portfolio_item'>
            <div className='grid sm:grid-cols-2 gap-y-8'>
              <article className='flex gap-4'>
                <div data-iframe-width="150" data-iframe-height="270" data-share-badge-id="8f38a841-a3f6-4d1d-acc1-6a56325ec9f3" data-share-badge-host="https://www.credly.com"></div>
              </article>
            </div>
            <div className='grid sm:grid-cols-2 gap-y-8'>
              <article className='flex gap-4'>
                <div data-iframe-width="150" data-iframe-height="270" data-share-badge-id="e97ef13a-ebff-4014-9378-ef8dca72d463" data-share-badge-host="https://www.credly.com"></div>
              </article>
            </div>
            <h3><a href="https://www.credly.com/users/ruben-marguerite" className='btn btn-primary' target="_blank" rel="noopener noreferrer">SAP</a></h3>
          </article>
          {/* <article className='portfolio_item'>
              <div className='portfolio_item_img'>
                <img src={FPRP} alt="FPRP" />
              </div>
              <h3>FPRP</h3>
              <small className='text-gray-300'>Site vitrine - Création et Administration<br />Wordpress et Front-end</small>
              <div className="portfolio_item_lnk">
                <a href="https://fprp77.fr" className='btn btn-primary' target="_blank" rel="noopener noreferrer">Voir le site</a>
              </div>
          </article> */}
        </div>
    </section>
  )
}

export default Portfolio