import React, { useState } from 'react'

import PhotoAlbum from 'react-photo-album'

import Lightbox from "yet-another-react-lightbox"
import "yet-another-react-lightbox/styles.css"

import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen"
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails"
import "yet-another-react-lightbox/plugins/thumbnails.css"
import Zoom from "yet-another-react-lightbox/plugins/zoom"

import photos from './photos'

const Gallery = () => {

    const [index, setIndex] = useState(-1)

    return (
    <section className='mt-10' id='portfolio'>
        <h5>Photographe : Coraline SALGUEIRO </h5>
        <h2>Domaine des Halles</h2>
        <div className="container mx-auto">
            <PhotoAlbum photos={photos} layout="rows" targetRowHeight={300} onClick={({ index }) => setIndex(index)} />
            <Lightbox
                slides={photos}
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                // enable optional lightbox plugins
                plugins={[Fullscreen, Thumbnails, Zoom]}
            />
        </div>
    </section>
    )
}

export default Gallery