import React from 'react'
import {MdWork} from 'react-icons/md'
import {IoEarthSharp} from 'react-icons/io5'
import {AiFillIdcard} from 'react-icons/ai'



const About = () => {
  return (
    <section id='about'>
      <h5>Pour en Savoir Plus</h5>
      <h2>A Propos de Moi</h2>

      <div className="container mx-auto">
        <div className="grid grid-cols-1 w-3/4 mx-auto">
          <div className='text-center max-sm:mx-8'>
            <div className="grid grid-cols-1 sm:grid-cols-[repeat(3,1fr)] gap-4 ">
              <article className="bg-daisy-500 rounded-[1rem] p-8 text-center border border-transparent duration-300 hover:bg-transparent hover:border-daisy-100 hover:cursor-default">
                <MdWork className='text-daisy text-2xl mb-4 mx-auto' />
                <h5 className='text-lg'>Expérience</h5>
                <small className='text-xs text-gray-300'>7+ Années</small>
              </article>
              <article className="bg-daisy-500 rounded-[1rem] p-8 text-center border border-transparent duration-300 hover:bg-transparent hover:border-daisy-100 hover:cursor-default">
                <IoEarthSharp className='text-daisy text-2xl mb-4 mx-auto' />
                <h5 className='text-lg'>International</h5>
                <small className='text-xs text-gray-300'>Anglais</small>
                <br />
                <small className='text-xs text-gray-300'>Japonais</small>
              </article>
              <article className="bg-daisy-500 rounded-[1rem] p-8 text-center border border-transparent duration-300 hover:bg-transparent hover:border-daisy-100 hover:cursor-default">
                <AiFillIdcard className='text-daisy text-2xl mb-4 mx-auto' />
                <h5 className='text-lg'>Entrepreneur</h5>
                <small className='text-xs text-gray-300'>2+ Années</small>
              </article>
            </div>
            <p className='my-8 mx-4 sm:my-8 s:mx-10 text-gray-300'>
            Avec plus de 7 ans d'expérience professionnelle dans 
            le monde de l'informatique, du développement et de la gestion de projet,
            j'ai eu l'occasion de participer à de nombreux projets 
            de toutes tailles avec une grande diversité de clients.
            <br /><br />
            Flexibilité et polyvalence sont essentielles pour avoir une bonne 
            compréhension du besoin et trouver une solution adaptée.
            </p>
            <a href="#contact" className="btn btn-primary mt-4">Discutons en</a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default About