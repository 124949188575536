import React from 'react'

export default function Cgu({ visible, onClose }) {

    const handleOnClose = () => {
        onClose();
    };

    if(!visible) return null;

  return (
    <div onClick={handleOnClose} className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center">
        <div className="bg-[#333333] w-3/4 lg:w-1/2 p-6 rounded">
            <h2 className='text-2xl text-daisy text-center mb-3'>Conditions Générales d'Utilisation</h2>
            <hr />
            <small className='text-gray-400 mb-4'>Dernière mise à jour : 05 Jan 2023</small>
            <div className=' bg-night px-4 w-full h-[30rem] overflow-y-scroll'>
                <h5 className="underline my-4">Mentions légales & Préambule</h5>
                <h6 className="pl-2 mb-3 underline text-sm"> Editeur</h6>
                <p className='mb-3 text-sm pl-4'>
                    OKUMO - Ruben MARGUERITE<br />
                    19 rue Mercière 69002 LYON<br />
                    ruben.m@okumo.fr
                </p>
                <h6 className="pl-2 mt-3 mb-3 underline text-sm"> Hébergeur</h6>
                <p className='mb-3 text-sm pl-4'>
                    O2SWITCH<br />
                    Siret 510 909 80700024<br />
                    RCS Clermont Ferrand<br />
                    SAS au capital de 100 000€<br />
                    Chemin des Pardiaux 63000 Clermont-Ferrand<br />
                    +33(0)4 44 44 60 40
                </p>
                <p className='mb-3 mt-4 text-sm pr-8'>
                    L'utilisation du site Okumo.fr est soumis aux présentes Conditions Générales d'Utilisation du site Okumo.fr (« Conditions Générales d'Utilisation »).
                </p>
                <p className='mb-3 text-sm pr-8'>
                    Les présentes Conditions Générales d'Utilisation et la Politique de Confidentialité peuvent être mises à jour à tout moment. Les Conditions Générales d’Utilisation et la Politique de Confidentialité en vigueur au moment de l’utilisation du site Okumo.fr sont celles qui vous sont opposables.<br />
                    Nous vous conseillons de vous tenir régulièrement informé des conditions en vigueur. Vous pouvez prendre connaissance à tout moment de la version des Conditions Générales d'Utilisation ou de la Politique de Confidentialité en vigueur en vous rendant dans la rubrique « Conditions Générales d'Utilisation» du site Okumo.fr.
                </p>
                <h5 className="underline my-4">1. Sécurité et confidentialité</h5>
                <p className='mb-3 text-sm pr-8'>
                    Les données à caractère personnel sont des informations relatives à une personne physique identifiée ou identifiable. Elles incluent par exemple le nom, l’adresse et le genre d’une personne.
                </p>
                <p className='mb-3 text-sm pr-8'>
                    Nous pouvons recueillir des données lorsque vous utilisez nos Plateformes numériques, notamment votre adresse IP ou d’autres données de navigation (y compris le navigateur, le système d’exploitation, le modèle de l’appareil), par le biais de cookies ou de technologies similaires placés sur votre appareil. Certains cookies sont nécessaires au bon fonctionnement de nos Plateformes numériques et d’autres sont utilisés à des fins d’analyse qui nous aident à vous fournir des services plus personnalisés et une meilleure expérience numérique. Pour plus d’informations sur les cookies et pour savoir comment modifier vos préférences, veuillez lire notre <button className='underline'>Cookie Policy</button>. 
                </p>
                <p className='mb-3 text-sm pr-8'>
                    Nous pouvons recueillir des données à caractère personnel indirectement (par exemple à partir de vos appareils électroniques qui interagissent avec nos sites Internet, formulaires électroniques ou applications mobiles (les « Plateformes numériques »)). 
                </p>
                <p className='mb-3 text-sm pr-8'>
                    Le site Okumo.fr n'est pas destiné aux enfants mineurs.
                </p>
                <h5 className="underline my-4">2. Propriété intellectuelle</h5>
                <p className='mb-3 text-sm pr-8'>
                    L'ensemble des éléments (dessins, modèles, illustrations, images, bandes sonores, textes, logos, marques...) constitutifs du site Okumo.fr est la propriété exclusive d'OKUMO ou de ses filiales. A l'exception des dérogations expressément prévue dans les Conditions Générales d'Utilisation, il vous est interdit de reproduire intégralement ou partiellement, par quelque procédé que ce soit, de distribuer, de publier, de transmettre, de modifier ou de vendre tout ou partie du contenu du site Okumo.fr, ou de créer des œuvres dérivées à partir de celui-ci.
                </p>
                <p className='mb-3 text-sm pr-8'>
                    La marque « Okumo », la marque figurative représentant une corolle, toutes les autres marques et logos liés à Okumo, déposés ou non, affichés sur le site Okumo.fr, ainsi que le nom de domaine « Okumo.fr » sont et demeureront la propriété exclusive d'OKUMO. Toute reproduction, distribution, transmission, modification ou utilisation de ces marques sans accord exprès et préalable d'OKUMO, pour quelque motif que ce soit, est interdite.
                </p>
                <p className='mb-3 text-sm pr-8'>
                    Il vous est interdit de supprimer tout avis de droit d'auteur, de marque ou d'autres droits exclusifs du site Okumo.fr ou de tout contenu qui y figure. Vous pouvez faire une seule copie des pages Internet publiées sur le site Okumo.fr pour votre usage privé, personnel et non commercial, sous réserve que toute copie de ces pages Internet conserve toutes les mentions de « copyright » (droit d'auteur) et de droits exclusifs qui y figurent.
                </p>
                <h5 className="underline my-4">3. Liens</h5>
                <p className='mb-3 text-sm pr-8'>
                    Le site Internet Okumo.fr peut contenir des liens vers des sites tiers non édités ou contrôlés par OKUMO. De tels liens sont uniquement fournis à titre de commodité ; ils ne peuvent et ne doivent pas être interprétés comme un aval exprès ou implicite de ces sites, de leur contenu ou de tout produit ou service qui y est offert.
                </p>
                <p className='mb-3 text-sm pr-8'>
                    Vous ne pouvez mettre en place un lien vers le site Internet Okumo.fr qu'avec l'autorisation écrite et expresse de OKUMO.
                </p>
                <h5 className="underline my-4">4. Limitation de responsabilité</h5>
                <p className='mb-3 text-sm pr-8'>
                    OKUMO ne pourra en aucun cas être tenue responsable vis-à-vis de vous ou d'un tiers de tout dommage indirect, incident, spécial ou accessoire - ce qui inclut, sans que cela soit exhaustif, toute perte de bénéfice ou autre perte indirecte -, résultant de votre utilisation du site Okumo.fr ou de votre incapacité à l'utiliser. La présente limitation de responsabilité s'applique dans toute la mesure autorisée par la loi.
                </p>
                <h5 className="underline my-4">5. Absence de garantie</h5>
                <p className='mb-3 text-sm pr-8'>
                    OKUMO ne peut garantir et affirmer : <br />
                    - que le site Internet Okumo.fr est exempt de virus, programmes d'écrasement des données, chevaux de Troie ou autre matériel de destruction, <br />
                    - que les informations contenues dans le site Okumo.fr sont exactes, complètes ou à jour.
                </p>
                <p className='mb-3 text-sm pr-8'>
                    Le site Okumo.fr peut contenir des imprécisions techniques ou d'autres défauts, OKUMO ne garantit pas la correction de ces défauts. Le site Internet Okumo.fr et le contenu de ce site sont présentés « en l'état » et « en fonction des disponibilités ». OKUMO exclut expressément toute forme de garantie, et notamment, toute garantie implicite relative aux conditions usuelles d'usage du site Okumo.fr ou de l'aptitude du site à un usage normal ou à une utilisation spécifique, à sa qualité ou au respect de toutes dispositions légales. La présente limitation de responsabilité s'applique dans toute la mesure autorisée par la loi.
                </p>
                <h5 className="underline my-4">6. Droit applicable - Litiges</h5>
                <p className='mb-3 text-sm pr-8'>
                    Les présentes Conditions Générales d'Utilisation sont régies et interprétées conformément au droit français conformément au règlement CE 593/2008 du 17 juin 2008. Ces Conditions Générales d'Utilisation sont écrites en français.
                </p>
                <p className='mb-3 text-sm pr-8'>
                    En cas de contestation liée à l’utilisation du Site, vous avez la possibilité de recourir à une procédure de médiation conventionnelle ou à toute autre procédure de mode alternatif de règlement des différends.
                </p>
                <p className='mb-3 text-sm pr-8'>
                    En cas de litige, conformément aux dispositions du règlement n° 44/2001 du 22 décembre 2000 : <br />
                    - vous pourrez saisir soit le tribunal du lieu où vous êtes domicilié, soit les tribunaux français, <br />
                    - OKUMO pourra saisir le tribunal du lieu où vous êtes domicilié.
                </p>
                <p className='mb-3 text-sm pr-8'>
                    OKUMO se réserve le droit de requérir et d'obtenir des injonctions ou ordonnances pour la défense de ses droits aux termes des présentes.
                </p>
            </div>
            <div className="text-center pt-4"><button onClick={handleOnClose} className="btn btn-primary">Fermer</button></div>
        </div>
    </div>
  );
}
