import React from 'react';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import Home from './pages';
import GalleryApp from './pages/GalleryApp';
import ErrorPage from "./pages/Notfound";

const App = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
      errorElement: <ErrorPage />,
    },
    {
      path: "index.html",
      element: <Home />,
      errorElement: <ErrorPage />,
    },
    {
      path: "pictures",
      element: <GalleryApp />,
    },
  ]);
  return (
    <>
      <React.StrictMode>
        <RouterProvider router={router} />         
      </React.StrictMode>
    </>
  );
}

export default App