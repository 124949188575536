

/* const breakpoints = [3840, 2400, 1080, 640, 384, 256, 128, 96, 64, 48]

const galleryPath = (name, width, height) =>
  '/assets/img/Gallery/RM_DomainedesHalles_${name}.jpg'

const galleryPhotos = [
  { name: "01", width: 1080, height: 1623 },
  { name: "02", width: 1080, height: 1623 },
  { name: "03", width: 1080, height: 719 },
  { name: "04", width: 1080, height: 719 },
  { name: "05", width: 1080, height: 719 },
  { name: "06", width: 1080, height: 1623 },
  { name: "07", width: 1080, height: 1623 },
  { name: "08", width: 1080, height: 1623 },
  { name: "09", width: 1080, height: 1623 }
] */

const photos = [
  {
    src: "/images/gallery/RM_DomainedesHalles_01.jpg",
    width: 1080,
    height: 1623
  },
  {
    src: "/images/gallery/RM_DomainedesHalles_02.jpg",
    width: 1080,
    height: 1623
  },
  {
    src: "/images/gallery/RM_DomainedesHalles_03.jpg",
    width: 1080,
    height: 719
  },
  {
    src: "/images/gallery/RM_DomainedesHalles_04.jpg",
    width: 1080,
    height: 719
  },
  {
    src: "/images/gallery/RM_DomainedesHalles_05.jpg",
    width: 1080,
    height: 719
  },
  {
    src: "/images/gallery/RM_DomainedesHalles_06.jpg",
    width: 1080,
    height: 1623
  },
  {
    src: "/images/gallery/RM_DomainedesHalles_07.jpg",
    width: 1080,
    height: 1623
  },
  {
    src: "/images/gallery/RM_DomainedesHalles_08.jpg",
    width: 1080,
    height: 1623
  },
  {
    src: "/images/gallery/RM_DomainedesHalles_09.jpg",
    width: 1080,
    height: 1623
  },
]


/* galleryPhotos.map(photo => {
  const width = breakpoints[0]
  const height = (photo.height / photo.width) * width

  return {
    src: galleryPath(photo.name, width, height),
    width,
    height,
    srcSet: breakpoints.map(breakpoint => {
      const height = Math.round((photo.height / photo.width) * breakpoint)
      return {
        src: galleryPath(photo.name, breakpoint, height),
        width: breakpoint,
        height
      }
    })
  } */
// })

export default photos