import React from 'react';
import Logo from '../assets/img/logo.png';

import { useRouteError } from "react-router-dom";


export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <header className='h-screen pt-40 sm:pt-[15rem] max-sm:px-1 overflow-hidden'>
      <div id='header' className="container mx-auto h-full text-center items-center relative ">
        <h4 className='mt-5 text-lg sm:text-xl'>404 - Not Found</h4>
        <h1 className='mt-5 text-4xl sm:text-5xl font-bold'>Ah !</h1>
        <h5 className='mt-5 text-gray-300 text-lg sm:text-xl'>Désolé mais cette page n'existe pas. :/</h5>
        <div className='mt-[4rem] sm:mt-[8rem] px-7 sm:px-14 pt-4 sm:pt-8 bg-gradient-to-b from-daisy to-transparent rounded-t-full w-40 sm:w-60 h-2/4 absolute left-[calc(50%-5rem)] sm:left-[calc(50%-7.5rem)]'>
          <img src={Logo} alt="Logo Okumo" />
        </div>
      </div>

    </header>
  );
}