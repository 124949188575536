import React, {useState} from 'react'
import Logo from '../../assets/img/logo.png'
import {BsLinkedin} from 'react-icons/bs'
import Cgu from './Cgu'
/* 
import Cookies from './Cookies' */

const Footer = () => {

  const [showCgu, setShowCgu] = useState(false)
  /*const [showCookies, setShowCookies] = useState(false) */

   const handleOnClose = () => {
    setShowCgu(false)
    /*setShowCookies(false)*/
  } 

  return (
    <footer /* onLoad={() => setShowCookies(true)} */ className='mt-16'>
      <div className='bg-gradient-to-b from-transparent to-daisy w-32 sm:w-52 h-26 sm:h-42 relative left-[calc(50%-4rem)] sm:left-[calc(50%-6.5rem)]'>
        <div className='text-center items-center px-7 sm:px-14 pt-20'>
          <img src={Logo} alt="Logo Okumo" />
          <h4 className='mt-5 text-lg font-bold sm:text-xl'>Okumo</h4>
          <a className='text-white mt-4 bg-[#333333] p-3 rounded-full' href="https://www.linkedin.com/in/ruben-marguerite-821178b0/" target="_blank" rel="noopener noreferrer"><BsLinkedin/></a>
        </div>
      </div>
      <div className='w-full pt-2 text-center'>
          <small className='bg-night p-3 rounded-full'>&copy; {new Date().getFullYear()} Okumo - Ruben MARGUERITE · Tous Droits Réservés - <button onClick={() => setShowCgu(true)} className="text-white underline">CGU</button></small>
      </div>
      
      <Cgu onClose={handleOnClose} visible={showCgu} />

      {/* <Cookies onClose={handleOnClose} visible={showCookies} /> */}

    </footer>
  )
}

export default Footer