import React from 'react'
import HSocials from './HSocials'
import Logo from '../../assets/img/logo.png'



const Header = () => {
  return (
    <header className="h-screen max-sm:px-1 overflow-hidden bg-[url('/src/assets/img/animation/head.gif')] bg-cover bg-bottom bg-no-repeat">
      <div className="pt-40 sm:pt-[15rem] h-full bg-black/30">
        <div id='header' className="container mx-auto h-full text-center items-center relative">
          <h4 className='mt-5 text-lg sm:text-xl'>Okumo</h4>
          <h1 className='mt-5 text-4xl sm:text-5xl font-bold'>Ruben MARGUERITE</h1>
          <h5 className='mt-5 mb-12 text-gray-300 text-lg sm:text-xl'>Business Analyst · Consultant Fonctionnel</h5>
          <HSocials />
          <div className='mt-[4rem] sm:mt-[8rem] px-7 sm:px-14 pt-4 sm:pt-8 bg-gradient-to-b from-daisy to-transparent rounded-t-full w-40 sm:w-60 h-2/4 absolute left-[calc(50%-5rem)] sm:left-[calc(50%-7.5rem)]'>
            <img src={Logo} alt="Logo Okumo" />
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header