import React from 'react'

import GHeader from '../components/header/GHeader'
import Gallery from '../components/gallery/Gallery'

const GalleryApp = () => {

  return (
    <>
       <GHeader />
       <Gallery />
    </>
  )
}

export default GalleryApp