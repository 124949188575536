import React from 'react'
import {HiMail} from 'react-icons/hi'
import {BsLinkedin} from 'react-icons/bs'
import {TbLetterM} from 'react-icons/tb'


const Contact = () => {
  return (
    <section id='contact'>
      <h5>Discutons</h5>
      <h2>Pour me contacter</h2>

      <div className="container mx-auto w-3/4 sm:w-1/2">
        <div className="grid grid-cols-1 lg:grid-cols-[repeat(3,1fr)] gap-4 ">
          <article className="bg-daisy-500 rounded-[1rem] p-8 text-center border border-transparent duration-300 hover:bg-transparent hover:border-daisy-100 hover:cursor-default">
            <HiMail className='text-daisy text-2xl mb-4 mx-auto' />
            <h5 className='text-lg'>Email</h5>
            <small className='text-xs text-gray-300'>ruben.m@okumo.fr</small>
            <div className="portfolio_item_lnk">
              <a href="mailto:ruben.m@okumo.fr" className=''>Envoyer un mail</a>
            </div>
          </article>
          <article className="bg-daisy-500 rounded-[1rem] p-8 text-center border border-transparent duration-300 hover:bg-transparent hover:border-daisy-100 hover:cursor-default">
            <BsLinkedin className='text-daisy text-2xl mb-4 mx-auto' />
            <h5 className='text-lg'>LinkedIn</h5>
            <small className='text-xs text-gray-300'>Ruben MARGUERITE</small>
            <div className="portfolio_item_lnk">
              <a href="https://www.linkedin.com/in/ruben-marguerite-821178b0/" className='' target="_blank" rel="noopener noreferrer">Voir mon profil</a>
            </div>
          </article>
          <article className="bg-daisy-500 rounded-[1rem] p-8 text-center border border-transparent duration-300 hover:bg-transparent hover:border-daisy-100 hover:cursor-default">
            <TbLetterM className='text-daisy text-2xl mb-4 mx-auto' />
            <h5 className='text-lg'>Malt</h5>
            <small className='text-xs text-gray-300'>Ruben MARGUERITE</small>
            <div className="portfolio_item_lnk">
              <a href="https://www.malt.fr/profile/rubenmarguerite" className='' target="_blank" rel="noopener noreferrer">Voir mon profil</a>
            </div>
          </article>
        </div>
      </div>
    </section>
  )
}

export default Contact