import React from 'react'
import {RiHome4Fill} from 'react-icons/ri'
import {BsPersonCircle} from 'react-icons/bs'
import {FaSwatchbook} from 'react-icons/fa'
import {IoMail} from 'react-icons/io5'
import {MdWork} from 'react-icons/md'
import {useState} from 'react'

const Nav = () => {
  const [activeNav, setActiveNav] = useState('#')
  
  return (
    <nav className='flex flex-col gap-3 sm:gap-4 max-w-max bg-[#444444] py-5 sm:py-7 px-1 z-[2] fixed right-2 sm:right-16 top-8 sm:top-14 rounded-full shadow-black shadow-lg'>
      <a href="#header" onClick={() => setActiveNav('#header')} className={activeNav === '#header' ? 'active' : ''}><RiHome4Fill /></a>
      <a href="#about" onClick={() => setActiveNav('#about')} className={activeNav === '#about' ? 'active' : ''}><BsPersonCircle /></a>
      <a href="#experience" onClick={() => setActiveNav('#experience')} className={activeNav === '#experience' ? 'active' : ''}><MdWork /></a>
      <a href="#portfolio" onClick={() => setActiveNav('#portfolio')} className={activeNav === '#portfolio' ? 'active' : ''}><FaSwatchbook /></a>
      <a href="#contact" onClick={() => setActiveNav('#contact')} className={activeNav === '#contact' ? 'active' : ''}><IoMail /></a>
    </nav>
  )
}

export default Nav