import React from 'react'
import {BsPatchCheckFill} from 'react-icons/bs'

const Experience = () => {
  return (
    <section id='experience'>
      <h5>Mon bagage technique</h5>
      <h2>Mes Compétences</h2>

      <div className="flex items-center justify-center">
        <div className="container grid lg:grid-cols-2 gap-y-8 sm:gap-8 mx-auto">

          {/* Front-end */}
          <div className='bg-daisy-500 rounded-2xl py-10 px-10 sm:px-20 border border-transparent max-sm:mx-8 hover:cursor-default'>
            <h3 className='text-center mb-10 text-daisy text-xl font-semibold'>Logiciels de gestion de projets</h3>
            <div className='grid sm:grid-cols-2 gap-y-8'>
              <article className='flex gap-4'>
                <BsPatchCheckFill className='text-daisy mt-[6px] ' />
                <h4>Redmine</h4>
                <small className='text-gray-300 mt-[3px]'>Expérimenté</small>
              </article>
              <article className='flex gap-4'>
                <BsPatchCheckFill className='mt-[6px] text-daisy' />
                <h4>Jira</h4>
                <small className='text-gray-300 mt-[3px]'>Intermédiaire</small>
              </article>
            </div>
            <h3 className='text-center mb-10 text-daisy text-xl font-semibold mt-8'>Applications</h3>
            <div className='grid sm:grid-cols-2 gap-y-8'>
              <article className='flex gap-4'>
                <BsPatchCheckFill className='text-daisy mt-[6px] ' />
                <h4>M365</h4>
                <small className='text-gray-300 mt-[3px]'>Expérimenté</small>
              </article>
              <article className='flex gap-4'>
                <BsPatchCheckFill className='mt-[6px] text-daisy' />
                <h4>SAP</h4>
                <small className='text-gray-300 mt-[3px]'>Notions</small>
              </article>
            </div>
            <h3 className='text-center mb-10 text-daisy text-xl font-semibold mt-8'>Bases de données</h3>
            <div className='grid sm:grid-cols-2 gap-y-8'>
              <article className='flex gap-4'>
                <BsPatchCheckFill className='text-daisy mt-[6px] ' />
                <h4>MySQL</h4>
                <small className='text-gray-300 mt-[3px]'>Expérimenté</small>
              </article>
            </div>
          </div>

          <div className='bg-daisy-500 rounded-2xl py-10 px-20 border border-transparent max-sm:mx-8 hover:cursor-default'>
            <h3 className='text-center mb-10 text-daisy text-xl font-semibold'>Méthodologie</h3>
            <div className='grid sm:grid-cols-2 gap-y-8'>
              <article className='flex gap-4'>
                <BsPatchCheckFill className='mt-[6px] text-daisy' />
                <h4>SCRUM</h4>
                <small className='text-gray-300 mt-[3px]'>Intermédiaire</small>
              </article>
            </div>
            <h3 className='text-center mb-10 text-daisy text-xl font-semibold mt-8'>Langages de programmation</h3>
            <div className='grid sm:grid-cols-2 gap-y-8'>
              <article className='flex gap-4'>
                <BsPatchCheckFill className='text-daisy mt-[6px] ' />
                <h4>HTML</h4>
                <small className='text-gray-300 mt-[3px]'>Expérimenté</small>
              </article>
              <article className='flex gap-4'>
                <BsPatchCheckFill className='text-daisy mt-[6px] ' />
                <h4>CSS</h4>
                <small className='text-gray-300 mt-[3px]'>Expérimenté</small>
              </article>
              <article className='flex gap-4'>
                <BsPatchCheckFill className='text-daisy mt-[6px] ' />
                <h4>Javascript</h4>
                <small className='text-gray-300 mt-[3px]'>Expérimenté</small>
              </article>
              <article className='flex gap-4'>
                <BsPatchCheckFill className='mt-[6px] text-daisy' />
                <h4>PHP</h4>
                <small className='text-gray-300 mt-[3px]'>Intermédiaire</small>
              </article>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Experience